$(function () {
    $('.b-product-images_small').slick({
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.b-product-images_big',
        arrows: true,
        //centerMode: true,
        focusOnSelect: true
    });

    $('.b-product-images_big').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.b-product-images_small',
        //draggable: false,
        arrows: false,
        fade: true,
        //centerMode: true,
        //focusOnSelect: true,
    });
    //}

    //$(document).on('click', '.b-product__big-images-item', function () {
    //    $.fancybox.open({href: $(this).attr('href'), type: 'image'});
    //    return false;
    //});
});