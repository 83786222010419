$(function () {
    $('.b-product-places_slick:not(.b-product-places_slick_6)').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 3,
        arrows: true,
        //centerMode: true,
        //focusOnSelect: true
    });

    $('.b-product-places_slick_6').slick({
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 3,
        arrows: true,
        //centerMode: true,
        //focusOnSelect: true
    });


});