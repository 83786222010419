$(function () {
    $('.b-reviews__list').slick({
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 3,
        arrows: true
    });

    $(".b-reviews__item").fancybox({
        type: 'image',
        openEffect: 'none',
        closeEffect: 'none',
        padding: 35
    });
});